import Head from "@/components/fragments/Head";
import Header from "@/components/fragments/Header";
import MainLayout from "@/components/fragments/main-layout/MainLayout";
import { DisplayError } from "@/components/fragments/errors/display-error";

export default function ErrorPage404() {
  return (
    <>
      <Head>
        <title>404 - MADRAS</title>
      </Head>
      <Header ddAllowPrivacy />
      <MainLayout activeMenu="" title="" ddAllowPrivacy>
        <DisplayError
          title={"404\nお探しのページは\n見つかりません"}
          message={
            "存在しないページです。\nurlを確認し、メニューから各管理画面に移動してください"
          }
          severity="warning"
          sessionId=""
        />
      </MainLayout>
    </>
  );
}
